export const devotionalCircuit = [
  {
    title: "Chamunda Devi",
    image: "chamunda-devi.jpg",
    position: { top: "10%", left: "10%" },
  },
  {
    title: "Brajeshwari Devi",
    image: "brajeshwari-devi.jpg",
    position: { top: "30%", left: "30%" },
  },
  {
    title: "Baglamukhi",
    image: "baglamukhi.jpg",
    position: { top: "50%", left: "50%" },
  },
  {
    title: "Jwala Ji Kangra",
    image: "jwalaji.jpg",
    position: { top: "70%", left: "70%" },
  },
];
