export const destinations = [
  {
    title: "Dharamshala",
    image: "dharmshala.jpg",
  },
  {
    title: "Bir Billing",
    image: "bir.jpg",
  },
  {
    title: "Manali",
    image: "manali.jpg",
  },
  {
    title: "Spiti",
    image: "spiti.jpg",
  },
  {
    title: "Chamba",
    image: "chamba.jpg",
  },
  {
    title: "Baijnath",
    image: "baijnath.jpg",
  },
];
