export const aboutData = [
  {
    title: "Our Mission",
    image: "aboutUs1.jpg",
    text: "Welcome to Travel Theoriz, the premier taxi service provider in Himachal Pradesh. We are dedicated to providing safe, reliable, and comfortable transportation solutions to our valued customers. Our service area covers all major destinations in Himachal Pradesh, and we offer both local and outstation services. We have a modern fleet of well-maintained vehicles. Our drivers are experienced, licensed, and knowledgeable about the local area, ensuring a smooth and hassle-free ride.",
  },
  {
    title: "Our Services",
    image: "aboutUs2.jpg",
    text: "At Travel Theoriz, we offer a range of services to meet the diverse needs of our customers. Whether you need airport transfers, sightseeing tours, or intercity travel, we have got you covered. We also offer customized travel packages that can be tailored to your specific requirements. Our pricing is competitive, transparent, and easy to understand. We offer flexible payment options, and our rates are all-inclusive, with no hidden charges or surprises. We also offer discounts for long-term bookings and referrals. Booking with us is simple and hassle-free. You can book online, by phone, or by email, and our customer service representatives are available 24/7 to assist you with any queries or concerns. We also offer easy cancellation and rescheduling options to ensure maximum flexibility.",
  },
  {
    title: "Our Values",
    image: "aboutUs3.jpg",
    text: "At Travel Theoriz, we are committed to providing excellent customer service, and we strive to exceed your expectations in every way. We value your feedback and are always looking for ways to improve our service. Thank you for choosing us as your trusted taxi service provider in Himachal Pradesh.",
  },
];
