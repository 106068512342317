export const carousal = [
  {
    title: "See the good.",
    image: "slide1.jpg",
  },
  {
    title: "Fuel your passion.",
    image: "slide2.jpg",
  },
  {
    title: "Now or never.",
    image: "slide3.jpg",
  },
  {
    title: "Remember to live.",
    image: "slide4.jpg",
  },
  {
    title: "Aspire to inspire.",
    image: "slide5.jpg",
  },
  {
    title: "Embrace the journey.",
    image: "spiti.jpg",
  },
];
