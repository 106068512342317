export const contacts = [
  {
    title: "Need a ride?",
    description:
      "Call us anytime for fast and reliable taxi service. Our drivers are available 24/7 to get you where you need to go. Contact us today to book your ride.",
    link: `tel:${8803660003}`,
    content: "+91 8803660003",
  },
  {
    title: "Need help? Contact us by email",
    description:
      "For support inquiries, please don't hesitate to contact us at traveltheoriz@gmail.com. Contact us for assistance via email.",
    link: "mailto:traveltheoriz@gmail.com",
    content: "traveltheoriz@gmail.com",
  },
  {
    title: "24/7 Taxi Service",
    description:
      "We understand that transportation needs can arise at any time of day or night. That's why we offer reliable and convenient taxi service 24 hours a day, 7 days a week.",
    link: "mailto:traveltheoriz@gmail.com",
    content: "traveltheoriz@gmail.com",
  },
];
