export const testimonials = [
  {
    title: "Anshika",
    image: "testimonial1.jpg",
    review:
      "I recently used Travel Theoriz and I couldn't be more impressed with their service. The fare was also very reasonable, especially considering the high level of service provided. I would highly recommend Travel Theoriz to anyone in need of reliable, safe and comfortable transportation. Thank you for such a great experience!",
  },
  {
    title: "Anchal",
    image: "testimonial2.jpg",
    review:
      "I had the pleasure of using Travel Theoriz recently, and I couldn't be more satisfied with their service. From the easy booking process to the comfortable, smooth ride, everything exceeded my expectations. The staff was professional and friendly, and the pricing was more than fair for the level of service provided. I highly recommend Travel Theoriz to anyone looking for dependable and top-notch transportation. Thank you for making my trip so enjoyable!",
  },
  {
    title: "Akanksha",
    image: "testimonial3.jpg",
    review:
      "The ride was smooth and comfortable, and I felt completely taken care of throughout the entire journey. What really impressed me was the attention to detail—from the friendly driver to the well-maintained vehicle. The price was fair, and honestly, for the level of service, I would have expected to pay more. I’ll definitely be using Travel Theoriz again and would recommend them to anyone who values punctuality, comfort, and reliability.",
  },
];
